import React from 'react';

function Footer() {
    return (
        <div className="footer">
            <p className="footer-text">Dalyn Baldwin and Caydn Baldwin</p>
            <p className="footer-text">IHUM 201, Section 3, Fall 2023</p>
        </div>
    )
}

export default Footer;