import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Header({ data }) {
    //console.log(data["early-renaissance"][0].artifacts[0]["the-holy-trinity"].name);
    const [isSubMenuVisibleLM, setSubMenuVisibleLM] = useState(false);
    const [isSubMenuVisibleER, setSubMenuVisibleER] = useState(false);
    const [isSubMenuVisibleEMR, setSubMenuVisibleEMR] = useState(false);
    const [isSubMenuVisibleMR, setSubMenuVisibleMR] = useState(false);
    const [isSubMenuVisibleHR, setSubMenuVisibleHR] = useState(false);
    const [isSubMenuVisibleLR, setSubMenuVisibleLR] = useState(false);
    const [isSubMenuVisibleIntro, setSubMenuVisibleIntro] = useState(false);
    const [linkActive, setLinkActive] = useState(false);

    function handleLink() {
        setLinkActive(true);
    }

    function handleMouseOverIntro() {
        setSubMenuVisibleLM(false);
        setSubMenuVisibleER(false);
        setSubMenuVisibleEMR(false);
        setSubMenuVisibleMR(false);
        setSubMenuVisibleHR(false);
        setSubMenuVisibleLR(false);
        setSubMenuVisibleIntro(true);
    }

    //Late Medieval
    function handleMouseOverLM() {
        setSubMenuVisibleLM(true);
        setSubMenuVisibleER(false);
        setSubMenuVisibleEMR(false);
        setSubMenuVisibleMR(false);
        setSubMenuVisibleHR(false);
        setSubMenuVisibleLR(false);
        setSubMenuVisibleIntro(false);
    }

    //Early Renaissance
    function handleMouseOverER() {
        setSubMenuVisibleER(true);
        setSubMenuVisibleLM(false);
        setSubMenuVisibleEMR(false);
        setSubMenuVisibleMR(false);
        setSubMenuVisibleHR(false);
        setSubMenuVisibleLR(false);
        setSubMenuVisibleIntro(false);
    }

    //Early-mid Renaissance
    function handleMouseOverEMR() {
        setSubMenuVisibleEMR(true);
        setSubMenuVisibleER(false);
        setSubMenuVisibleMR(false);
        setSubMenuVisibleHR(false);
        setSubMenuVisibleLR(false);
        setSubMenuVisibleLM(false);
        setSubMenuVisibleIntro(false);
    }

    //Mid Renaissance
    function handleMouseOverMR() {
        setSubMenuVisibleMR(true);
        setSubMenuVisibleER(false);
        setSubMenuVisibleEMR(false);
        setSubMenuVisibleHR(false);
        setSubMenuVisibleLR(false);
        setSubMenuVisibleLM(false);
        setSubMenuVisibleIntro(false);
    }

    //High Renaissance
    function handleMouseOverHR() {
        setSubMenuVisibleHR(true);
        setSubMenuVisibleER(false);
        setSubMenuVisibleEMR(false);
        setSubMenuVisibleMR(false);
        setSubMenuVisibleLR(false);
        setSubMenuVisibleLM(false);
        setSubMenuVisibleIntro(false);
    }

    //Late Renaissance
    function handleMouseOverLR() {
        setSubMenuVisibleLR(true);
        setSubMenuVisibleER(false);
        setSubMenuVisibleEMR(false);
        setSubMenuVisibleMR(false);
        setSubMenuVisibleHR(false);
        setSubMenuVisibleLM(false);
        setSubMenuVisibleIntro(false);
    }

    //Close all submenus
    function handleMouseOut() {
        setSubMenuVisibleLM(false);
        setSubMenuVisibleER(false);
        setSubMenuVisibleEMR(false);
        setSubMenuVisibleMR(false);
        setSubMenuVisibleHR(false);
        setSubMenuVisibleLR(false);
        setSubMenuVisibleIntro(false);
    }

    const lm = "late-medieval";
    const er = "early-renaissance";
    const emr = "early-mid-renaissance";
    const mr = "mid-renaissance";
    const hr = "high-renaissance";
    const lr = "late-renaissance";

    return (
        <div>
            <nav>
                <ul className="nav-list">
                    {/*<li><Link to="/" className='nav-item'>Home</Link></li>*/}
                    {/* Introduction */}
                    <Link to={`/exhibit`} className='nav-item'>
                        Introduction
                    </Link>
                    <li className='dropdown'>
                        <Link to={`/exhibit/${lm}`} className='nav-item'>{data["late-medieval"][0].name}</Link>
                        <div className="dropdown-content" onMouseLeave={handleMouseOut}>
                            {/* Late Medieval */}
                            <Link to={`/exhibit/${lm}/the-last-judgement`}>
                                {`${data["late-medieval"][0].artifacts[0]["the-last-judgement"].name} (${data["late-medieval"][0].artifacts[0]["the-last-judgement"]["artist-last"]}, ${data["late-medieval"][0].artifacts[0]["the-last-judgement"].year})`}
                            </Link>
                        </div>
                    </li>
                    <li className='dropdown'>
                        {/* Early Renaissance */}
                        <Link to={`/exhibit/${er}`} className='nav-item'>
                            {data["early-renaissance"][0].name}
                        </Link>
                        <div className="dropdown-content" onMouseLeave={handleMouseOut}>
                            <Link to={`/exhibit/${er}/the-holy-trinity`}>
                                {`${data["early-renaissance"][0].artifacts[0]["the-holy-trinity"].name} (${data["early-renaissance"][0].artifacts[0]["the-holy-trinity"]["artist-last"]}, ${data["early-renaissance"][0].artifacts[0]["the-holy-trinity"].year})`}
                            </Link>
                            <Link to={`/exhibit/${er}/self-portrait`}>
                                {`${data["early-renaissance"][0].artifacts[0]["self-portrait"].name} (${data["early-renaissance"][0].artifacts[0]["self-portrait"]["artist-last"]}, ${data["early-renaissance"][0].artifacts[0]["self-portrait"].year})`}
                            </Link>
                            <Link to={`/exhibit/${er}/the-birth-of-venus`}>
                                {`${data["early-renaissance"][0].artifacts[0]["the-birth-of-venus"].name} (${data["early-renaissance"][0].artifacts[0]["the-birth-of-venus"]["artist-last"]}, ${data["early-renaissance"][0].artifacts[0]["the-birth-of-venus"].year})`}
                            </Link>
                            <Link to={`/exhibit/${er}/vitruvian-man`}>
                                {`${data["early-renaissance"][0].artifacts[0]["vitruvian-man"].name} (${data["early-renaissance"][0].artifacts[0]["vitruvian-man"]["artist-last"]}, ${data["early-renaissance"][0].artifacts[0]["vitruvian-man"].year})`}
                            </Link>
                        </div>
                    </li>
                    <li className='dropdown'>
                        {/* High Renaissance */}
                        <Link to={`/exhibit/${hr}`} className='nav-item'>
                            {data["high-renaissance"][0].name}
                        </Link>
                        <div className="dropdown-content dropdown-content-lr" onMouseLeave={handleMouseOut}>
                            <Link to={`/exhibit/${hr}/mona-lisa`}>
                                {`${data["high-renaissance"][0].artifacts[0]["mona-lisa"].name} (${data["high-renaissance"][0].artifacts[0]["mona-lisa"]["artist-last"]}, ${data["high-renaissance"][0].artifacts[0]["mona-lisa"].year})`}
                            </Link>
                            <Link to={`/exhibit/${hr}/the-school-of-athens`}>
                                {`${data["high-renaissance"][0].artifacts[0]["the-school-of-athens"].name} (${data["high-renaissance"][0].artifacts[0]["the-school-of-athens"]["artist-last"]}, ${data["high-renaissance"][0].artifacts[0]["the-school-of-athens"].year})`}
                            </Link>
                            <Link to={`/exhibit/${hr}/assumption-of-the-virgin`}>
                                {`${data["high-renaissance"][0].artifacts[0]["assumption-of-the-virgin"].name} (${data["high-renaissance"][0].artifacts[0]["assumption-of-the-virgin"]["artist-last"]}, ${data["high-renaissance"][0].artifacts[0]["assumption-of-the-virgin"].year})`}
                            </Link>
                        </div>
                    </li>
                    <li className='dropdown'>
                        {/* Late Renaissance */}
                        <Link to={`/exhibit/${lr}`} className='nav-item'>
                            {data["late-renaissance"][0].name}
                        </Link>
                        <div className="dropdown-content dropdown-content-lr" onMouseLeave={handleMouseOut}>
                            <Link to={`/exhibit/${lr}/bacchus-and-ariadne`}>
                                {`${data["late-renaissance"][0].artifacts[0]["bacchus-and-ariadne"].name} (${data["late-renaissance"][0].artifacts[0]["bacchus-and-ariadne"]["artist-last"]}, ${data["late-renaissance"][0].artifacts[0]["bacchus-and-ariadne"].year})`}
                            </Link>
                            <Link to={`/exhibit/${lr}/the-harvesters`}>
                                {`${data["late-renaissance"][0].artifacts[0]["the-harvesters"].name} (${data["late-renaissance"][0].artifacts[0]["the-harvesters"]["artist-last"]}, ${data["late-renaissance"][0].artifacts[0]["the-harvesters"].year})`}
                            </Link>
                            <Link to={`/exhibit/${lr}/the-calling-of-saint-matthew`}>
                                {`${data["late-renaissance"][0].artifacts[0]["the-calling-of-saint-matthew"].name} (${data["late-renaissance"][0].artifacts[0]["the-calling-of-saint-matthew"]["artist-last"]}, ${data["late-renaissance"][0].artifacts[0]["the-calling-of-saint-matthew"].year})`}
                            </Link>
                        </div>
                    </li>
                    {/*<li className='dropdown'>
                        <Link to="/exhibit" className='nav-item'>Exhibits</Link>
                        <div className="dropdown-content" onMouseLeave={handleMouseOut}>

                            {/* Introduction 
                            <Link to={`/exhibit`} className='parent' style={{borderBottom: 'solid rgb(80, 30, 30) 1px', textAlign: 'left', backgroundColor: '#eacfcf', fontWeight: isSubMenuVisibleIntro ? 'bold' : ''}} onMouseOver={handleMouseOverIntro}>
                                Introduction
                            </Link>

                            {/* Late Medieval 
                            <Link to={`/exhibit/${lm}`} className='parent' style={{borderBottom: 'solid rgb(80, 30, 30) 1px', textAlign: 'left', backgroundColor: '#eacfcf', fontWeight: isSubMenuVisibleLM ? 'bold' : ''}} onMouseOver={handleMouseOverLM}>
                                {data["late-medieval"][0].name}
                            </Link>
                            <div className='child' style={{display: isSubMenuVisibleLM ? 'block' : 'none'}}>
                                <Link style={{borderBottom: 'solid rgb(80, 30, 30) 1px'}} to={`/exhibit/${lm}/the-last-judgement`}>
                                    {`${data["late-medieval"][0].artifacts[0]["the-last-judgement"].name} (${data["late-medieval"][0].artifacts[0]["the-last-judgement"]["artist-last"]}, ${data["late-medieval"][0].artifacts[0]["the-last-judgement"].year})`}
                                </Link>
                            </div>

                            {/* Early Renaissance 
                            <Link to={`/exhibit/${er}`} style={{borderBottom: 'solid rgb(80, 30, 30) 1px', textAlign: 'left', backgroundColor: '#eacfcf', fontWeight: isSubMenuVisibleER ? 'bold' : ''}} onMouseOver={handleMouseOverER}>
                                {data["early-renaissance"][0].name}
                            </Link>
                            <div className='child' style={{display: isSubMenuVisibleER ? 'block' : 'none'}}>
                                <Link style={{borderBottom: 'solid rgb(80, 30, 30) 1px'}} to={`/exhibit/${er}/the-holy-trinity`}>
                                    {`${data["early-renaissance"][0].artifacts[0]["the-holy-trinity"].name} (${data["early-renaissance"][0].artifacts[0]["the-holy-trinity"]["artist-last"]}, ${data["early-renaissance"][0].artifacts[0]["the-holy-trinity"].year})`}
                                </Link>
                                <Link style={{borderBottom: 'solid rgb(80, 30, 30) 1px'}} to={`/exhibit/${er}/self-portrait`}>
                                    {`${data["early-renaissance"][0].artifacts[0]["self-portrait"].name} (${data["early-renaissance"][0].artifacts[0]["self-portrait"]["artist-last"]}, ${data["early-renaissance"][0].artifacts[0]["self-portrait"].year})`}
                                </Link>
                                <Link style={{borderBottom: 'solid rgb(80, 30, 30) 1px'}} to={`/exhibit/${er}/the-birth-of-venus`}>
                                    {`${data["early-renaissance"][0].artifacts[0]["the-birth-of-venus"].name} (${data["early-renaissance"][0].artifacts[0]["the-birth-of-venus"]["artist-last"]}, ${data["early-renaissance"][0].artifacts[0]["the-birth-of-venus"].year})`}
                                </Link>
                                <Link style={{borderBottom: 'solid rgb(80, 30, 30) 1px'}} to={`/exhibit/${er}/vitruvian-man`}>
                                    {`${data["early-renaissance"][0].artifacts[0]["vitruvian-man"].name} (${data["early-renaissance"][0].artifacts[0]["vitruvian-man"]["artist-last"]}, ${data["early-renaissance"][0].artifacts[0]["vitruvian-man"].year})`}
                                </Link>
                            </div>

                            {/* Early-mid Renaissance 
                            <Link to={`/exhibit/${emr}/the-birth-of-venus`} style={{textAlign: 'left', backgroundColor: '#eacfcf', fontWeight: isSubMenuVisibleEMR ? 'bold' : ''}} onMouseOver={handleMouseOverEMR}>
                                {data["early-mid-renaissance"][0].name}
                            </Link>
                            <div className='child' style={{display: isSubMenuVisibleEMR ? 'block' : 'none'}}>
                                <Link style={{borderBottom: 'solid rgb(80, 30, 30) 1px'}} to={`/exhibit/${emr}/the-birth-of-venus`}>
                                    {`${data["early-mid-renaissance"][0].artifacts[0]["the-birth-of-venus"].name} (${data["early-mid-renaissance"][0].artifacts[0]["the-birth-of-venus"]["artist-last"]}, ${data["early-mid-renaissance"][0].artifacts[0]["the-birth-of-venus"].year})`}
                                </Link>
                            </div>

                             Mid Renaissance 
                            <Link to={`/exhibit/${mr}/vitruvian-man`} style={{textAlign: 'left', backgroundColor: '#eacfcf', fontWeight: isSubMenuVisibleMR ? 'bold' : ''}} onMouseOver={handleMouseOverMR}>
                                {data["mid-renaissance"][0].name}
                            </Link>
                            <div className='child' style={{display: isSubMenuVisibleMR ? 'block' : 'none'}}>
                                <Link style={{borderBottom: 'solid rgb(80, 30, 30) 1px'}} to={`/exhibit/${mr}/vitruvian-man`}>
                                    {`${data["mid-renaissance"][0].artifacts[0]["vitruvian-man"].name} (${data["mid-renaissance"][0].artifacts[0]["vitruvian-man"]["artist-last"]}, ${data["mid-renaissance"][0].artifacts[0]["vitruvian-man"].year})`}
                                </Link>
                                <Link style={{borderBottom: 'solid rgb(80, 30, 30) 1px'}} to={`/exhibit/${mr}/the-school-of-athens`}>
                                    {`${data["mid-renaissance"][0].artifacts[0]["the-school-of-athens"].name} (${data["mid-renaissance"][0].artifacts[0]["the-school-of-athens"]["artist-last"]}, ${data["mid-renaissance"][0].artifacts[0]["the-school-of-athens"].year})`}
                                </Link>
                            </div>

                            {/* High Renaissance 
                            <Link to={`/exhibit/${hr}`} style={{borderBottom: 'solid rgb(80, 30, 30) 1px', textAlign: 'left', backgroundColor: '#eacfcf', fontWeight: isSubMenuVisibleHR ? 'bold' : ''}} onMouseOver={handleMouseOverHR}>
                                {data["high-renaissance"][0].name}
                            </Link>
                            <div className='child' style={{display: isSubMenuVisibleHR ? 'block' : 'none'}}>
                                <Link style={{borderBottom: 'solid rgb(80, 30, 30) 1px'}} to={`/exhibit/${hr}/mona-lisa`}>
                                    {`${data["high-renaissance"][0].artifacts[0]["mona-lisa"].name} (${data["high-renaissance"][0].artifacts[0]["mona-lisa"]["artist-last"]}, ${data["high-renaissance"][0].artifacts[0]["mona-lisa"].year})`}
                                </Link>
                                <Link style={{borderBottom: 'solid rgb(80, 30, 30) 1px'}} to={`/exhibit/${hr}/the-school-of-athens`}>
                                    {`${data["high-renaissance"][0].artifacts[0]["the-school-of-athens"].name} (${data["high-renaissance"][0].artifacts[0]["the-school-of-athens"]["artist-last"]}, ${data["high-renaissance"][0].artifacts[0]["the-school-of-athens"].year})`}
                                </Link>
                                <Link style={{borderBottom: 'solid rgb(80, 30, 30) 1px'}} to={`/exhibit/${hr}/assumption-of-the-virgin`}>
                                    {`${data["high-renaissance"][0].artifacts[0]["assumption-of-the-virgin"].name} (${data["high-renaissance"][0].artifacts[0]["assumption-of-the-virgin"]["artist-last"]}, ${data["high-renaissance"][0].artifacts[0]["assumption-of-the-virgin"].year})`}
                                </Link>
                            </div>

                            {/* Late Renaissance 
                            <Link to={`/exhibit/${lr}`} style={{borderBottom: 'solid rgb(80, 30, 30) 1px', textAlign: 'left', backgroundColor: '#eacfcf', fontWeight: isSubMenuVisibleLR ? 'bold' : ''}} onMouseOver={handleMouseOverLR}>
                                {data["late-renaissance"][0].name}
                            </Link>
                            <div className='child' style={{display: isSubMenuVisibleLR ? 'block' : 'none'}}>
                                <Link style={{borderBottom: 'solid rgb(80, 30, 30) 1px'}} to={`/exhibit/${lr}/bacchus-and-ariadne`}>
                                    {`${data["late-renaissance"][0].artifacts[0]["bacchus-and-ariadne"].name} (${data["late-renaissance"][0].artifacts[0]["bacchus-and-ariadne"]["artist-last"]}, ${data["late-renaissance"][0].artifacts[0]["bacchus-and-ariadne"].year})`}
                                </Link>
                                <Link style={{borderBottom: 'solid rgb(80, 30, 30) 1px'}} to={`/exhibit/${lr}/the-harvesters`}>
                                    {`${data["late-renaissance"][0].artifacts[0]["the-harvesters"].name} (${data["late-renaissance"][0].artifacts[0]["the-harvesters"]["artist-last"]}, ${data["late-renaissance"][0].artifacts[0]["the-harvesters"].year})`}
                                </Link>
                                <Link style={{borderBottom: 'solid rgb(80, 30, 30) 1px'}} to={`/exhibit/${lr}/the-calling-of-saint-matthew`}>
                                    {`${data["late-renaissance"][0].artifacts[0]["the-calling-of-saint-matthew"].name} (${data["late-renaissance"][0].artifacts[0]["the-calling-of-saint-matthew"]["artist-last"]}, ${data["late-renaissance"][0].artifacts[0]["the-calling-of-saint-matthew"].year})`}
                                </Link>
                            </div>
                        </div>
                    </li>*/}
                </ul>
            </nav>
        </div>
    )
}

export default Header;