import React from "react";
import Next from '../components/Next';
import NextPeriod from '../components/NextPeriod';
import Previous from '../components/Previous';
import PreviousPeriod from '../components/PreviousPeriod';
import { Link } from 'react-router-dom';

function Artifact({ artifact, previousPeriod=null, previous=null, next=null, nextPeriod=null, exhibitPage=false }) {  
    let imgSrc;
    
    if (!exhibitPage) {
        (artifact.name !== "Self Portrait" && artifact.name !== "Bacchus and Ariadne" && artifact.name !== "The Birth of Venus") ?
        imgSrc =  <>Image Source: <a href={artifact.img} target="_blank">{artifact.imgPage}</a></>
        :
        (artifact.name === "Self Portrait") ?
        imgSrc = <>Image Source: <a href="https://www.nga.gov/collection/art-object-page.43845.html" target="_blank">{artifact.imgPage}</a></>
        :
        (artifact.name === "The Birth of Venus") ?
        imgSrc = <>Image Source: <a href="https://www.uffizi.it/en/artworks/birth-of-venus" target="_blank">{artifact.imgPage}</a></>
        :
        imgSrc = <>Image Source: <a href="https://artuk.org/discover/artworks/bacchus-and-ariadne-114356" target="_blank">{artifact.imgPage}</a></>
    }


    return (
        <>
            {!exhibitPage ? 
                <>
                    <div className='directional'>
                    {previousPeriod !== null ? 
                        <PreviousPeriod url={previousPeriod} /> 
                        : 
                        ''
                    }

                    {previous !== null && next !== null ? 
                        <Previous url={previous} marginRight={true} /> 
                        :
                        previous !== null ?
                            <Previous url={previous} />
                            :
                            ''
                    }
                    {next !== null ? 
                        <Next url={next} />
                        :
                        ''
                    }
                    {nextPeriod !== null ?
                        <NextPeriod url={nextPeriod} />
                        :
                        ''
                    }
                </div>
                <div className="artifact-page">
                    <div className="author-card">
                        <img src={artifact.artist.img} alt={artifact.artist.name} />
                        <div className="author-information">
                            <h1>{artifact.artist.name}</h1>
                            <h2>{artifact.artist.dates}</h2>
                            {Array.isArray(artifact.artist.period) ? 
                                <h3>
                                    {artifact.artist.period[0]}
                                    <br />
                                    and
                                    <br />
                                    {artifact.artist.period[1]}
                                </h3>
                                :
                                <h3>{artifact.artist.period}</h3>
                            }
                            <h3 style={{fontSize: '0.75rem'}}>
                                Image Source: <a href={artifact.artist.img} target="_blank">{artifact.artist.imgPage}</a>
                                <br />
                                Information Source: <a href={artifact.artist.info} target="_blank">{artifact.artist.infoPage}</a>
                            </h3>
                        </div>
                    </div>
                    {artifact["artist-last"] === "Masaccio" ?
                        <div className="artifact-card" style={{marginBottom: '100px'}}>
                            <img src={artifact.img} alt={artifact.name} />
                            <div className="artifact-information">
                                <h1>{artifact.name}</h1>
                                <br />
                                <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', paddingLeft: '10px', paddingRight: '10px', width: '100%'}}>
                                    <div className="flex-test" style={{textAlign: 'left', alignItems: 'left', minWidth:'50%', maxWidth: '100%'}}>
                                        <h2 style={{fontWeight: 'bolder', width: 'fit-content', paddingRight: '10px'}}>Year:</h2>
                                        <h2 style={{fontWeight: 'normal'}}>{artifact.year}</h2>
                                    </div>
                                    <div className="flex-test" style={{textAlign: 'left', alignItems: 'left', minWidth:'50%', maxWidth: '100%'}}>
                                        <h2 style={{fontWeight: 'bolder', width: 'fit-content', paddingRight: '10px'}}>Medium:</h2>
                                        <h2 style={{fontWeight: 'normal'}}>{artifact.medium}</h2>
                                    </div>
                                    <div className="flex-test" style={{textAlign: 'left', alignItems: 'left', minWidth:'50%', maxWidth: '100%'}}>
                                        <h2 style={{fontWeight: 'bolder', width: 'fit-content', paddingRight: '10px'}}>Location:</h2>
                                        <h2 style={{fontWeight: 'normal'}}>{artifact.location}</h2>
                                    </div>
                                    <br />
                                    <h3 className="flex-test">{artifact.description}</h3>
                                    
                                    <h3 style={{fontSize: '0.75rem', zIndex: 1000}}>
                                        {imgSrc}
                                        <br />
                                        Information Source: Class Notes, <a href={artifact.info} target="_blank">{artifact.infoPage}</a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="artifact-card" style={{marginBottom: '100px'}}>
                            <img src={artifact.img} alt={artifact.name} />
                            <div className="artifact-information">
                                <h1>{artifact.name}</h1>
                                <br />
                                <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', paddingLeft: '10px', paddingRight: '10px', width: '100%'}}>
                                    <div className="flex-test" style={{display: 'flex', flexDirection: 'row', textAlign: 'left', alignItems: 'left', minWidth:'50%', maxWidth: '100%'}}>
                                        <h2 style={{fontWeight: 'bolder', width: 'fit-content', paddingRight: '10px'}}>Year:</h2>
                                        <h2 style={{fontWeight: 'normal'}}>{artifact.year}</h2>
                                    </div>
                                    <div className="flex-test" style={{display: 'flex', flexDirection: 'row', textAlign: 'left', alignItems: 'left', minWidth:'50%', maxWidth: '100%'}}>
                                        <h2 style={{fontWeight: 'bolder', width: 'fit-content', paddingRight: '10px'}}>Medium:</h2>
                                        <h2 style={{fontWeight: 'normal'}}>{artifact.medium}</h2>
                                    </div>
                                    <div className="flex-test" style={{display: 'flex', flexDirection: 'row', textAlign: 'left', alignItems: 'left', minWidth:'50%', maxWidth: '100%'}}>
                                        <h2 style={{fontWeight: 'bolder', width: 'fit-content', paddingRight: '10px'}}>Location:</h2>
                                        <h2 style={{fontWeight: 'normal'}}>{artifact.location}</h2>
                                    </div>
                                    <br />
                                    <h3 className="flex-test" style={{textAlign: 'left'}}>{artifact.description}</h3>
                                    <h3 style={{fontSize: '0.75rem'}}>
                                        {imgSrc}
                                        <br />
                                        Information Source: Class Notes, <a href={artifact.info} target="_blank">{artifact.infoPage}</a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                </>
                :
                <div className="period-intro" style={{position: 'relative', marginTop: '0', marginBottom: '100px', marginTop: '30px'}}>
                    <h2 id="introduction" style={{width: 'fit-content', color: 'rgb(80, 30, 30)', borderTop: 'solid 3px rgb(80, 30, 30)', borderBottom: 'solid 3px rgb(80, 30, 30)', textAlign: 'center', alignSelf: 'center', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '20px', paddingRight: '20px'}}>Introduction</h2>
                    <p style={{fontSize: '1.15rem', textAlign: 'left', margin: '0', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', color: 'rgb(134, 56, 56)'}}>
                        This exhibition highlights the dynamics of art from the Late Medieval period {'(c. 1300)'} to the Late Renaissance period {'(c. 1600)'}. The curatorial
                        scheme for the exhibition follows this timeline and will walk you through the different periods while highlighting specific pieces of art from each period.
                        The chosen curatorial scheme is logical because it is the most commonly used among exhibitions of this nature, but it also fulfills the intention of exploring
                        the dynamics of change from the Late Medieval period to the Late Renaissance period. 
                        <br /><br />
                        The exhibition will be divided into four exhibits, each of which will take the viewer through a journey in the order of {"(1)"} Late Medieval, {"(2) "} 
                        Early Renaissance, {"(3)"} High Renaissance, and {"(4)"} Late Renaissance. The exhibition will also include a brief introduction to each period, which will 
                        provide a general overview of the period and its dynamics. Each of these periods will then contain at least one piece of art, but may contain more
                        due to their importance and prolonged influence on the art world. Lastly, each piece will analyze its relation to the specific style of the period and will address the 
                        piece's dynamics. 
                        <br /><br />
                        These four periods were chosen because they each held strong significance in the world of art and its dynamics. The Late Medieval period, for example, was the
                        period that influenced the start of the Renaissance. The Early Renaissance opened the door to artistic exploration, allowing artists to experiment with
                        new techniques while bending the rules of what art was meant to be. The High Renaissance was the period that saw the most artistic growth and development, and it
                        tends to have a more specific end point, characterized by the death of Raphael in 1520&nbsp;
                        <span style={{fontSize: '0.75rem'}}>(Source: <a target="_blank" href="https://www.khanacademy.org/humanities/renaissance-reformation/high-ren-florence-rome/high-renaissance1/a/raphael-an-introduction">Khan Academy</a>)</span>. 
                        The Late Renaissance period was characterized by a more dramatic and emotional style of art, rather than art that emphasized depth and perception.
                    </p>
                    <br />
                    <p style={{fontSize: '1.15rem', textAlign: 'left', margin: '0', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', color: 'rgb(134, 56, 56)'}}>
                        <span style={{fontSize: '1.2rem', fontWeight: 'bold'}}>Exhibit 1: Late Medieval Art {'(c. 1300-1400)'}</span>
                        <br />
                        The Late Medieval exhibit will highlight pieces from circa 1300 to circa 1400. This exhibit will focus on the transition from the Medieval period to the Renaissance period.
                        Pieces in this exhibit will display characteristics of Gothic art, particularly within the realm of religion&nbsp;
                        <span style={{fontSize: '0.75rem'}}>(Source: <a target="_blank" href="https://www.britannica.com/art/Gothic-art/High-Gothic">Britannica</a>)</span>. 
                        The pieces will also display the beginnings of the Renaissance, including more vibrant colors&nbsp;
                        <span style={{fontSize: '0.75rem'}}>(Source: <a target="_blank" href="https://www.metmuseum.org/toah/hd/iptg/hd_iptg.htm">Metropolitan Museum</a>)</span>.
                    </p>
                    <br />
                    <p style={{fontSize: '1.15rem', textAlign: 'left', margin: '0', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', color: 'rgb(134, 56, 56)'}}>
                        <span style={{fontSize: '1.2rem', fontWeight: 'bold'}}>Exhibit 2: Early Renaissance Art {'(c. 1400-1500)'}</span>
                        <br />
                        The Early Renaissance exhibit will highlight pieces from circa 1400 to circa 1500. The pieces in this exhibit will display characteristics of the Renaissance, including more vibrant colors 
                        and more realistic depictions of people and objects&nbsp;
                        <span style={{fontSize: '0.75rem'}}>(Source: <a target="_blank" href="https://www.theartstory.org/movement/early-renaissance/">The Art Story</a>)</span>. 
                        They will also show a heavy emphasis on Fresco painting, which was a technique that was used to paint on walls and ceilings&nbsp;
                        <span style={{fontSize: '0.75rem'}}>(Source: <a target="_blank" href="https://www.nga.gov/features/slideshows/the-early-renaissance-in-florence.html">National Gallery of Art</a>)</span>. 
                        Many of the pieces from this period can be found in churches, especially Catholic.
                        <br />
                    </p>
                    <br />
                    <p style={{fontSize: '1.15rem', textAlign: 'left', margin: '0', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', color: 'rgb(134, 56, 56)'}}>
                        <span style={{fontSize: '1.2rem', fontWeight: 'bold'}}>Exhibit 3: High Renaissance Art {'(c. 1500-1520)'}</span>
                        <br />
                        The High Renaissance exhibit will highlight pieces from circa 1500 to circa 1520. These pieces will highlight the introduction of shadows and depth in paintings, which was a technique
                        that was used to make paintings appear more realistic and symbols of divinity. This period also saw an emphasis on oil painting, which was a technique that was used to paint on canvas and tempura&nbsp;
                        <span style={{fontSize: '0.75rem'}}>(Source: <a target="_blank" href="https://www.theartstory.org/movement/high-renaissance/">The Art Story</a>)</span>. Many of the pieces
                        from this period focused less on religion and more on individuality. The end of this period is characterized by the death of Raphael in 1520, which is unique because the other periods
                        do not have a specific and definite end point.
                    </p>
                    <br />
                    <p style={{fontSize: '1.15rem', textAlign: 'left', margin: '0', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', color: 'rgb(134, 56, 56)'}}>
                        <span style={{fontSize: '1.2rem', fontWeight: 'bold'}}>Exhibit 4: Late Renaissance Art {'(c. 1520-1600)'}</span>
                        <br />
                        The Late Renaissance exhibit will highlight pieces from circa 1520 to circa 1600. This period saw the end of the Renaissance and heavily influenced the Baroque period&nbsp;
                        <span style={{fontSize: '0.75rem'}}>(Source: <a target="_blank" href="https://useum.org/Baroque/History-of-Baroque">UMuseum</a>)</span>. The pieces in this
                        exhibit will highlight the introduction of more dramatic and emotional art, which was essential to connecting emotionally with audiences&nbsp;
                        <span style={{fontSize: '0.75rem'}}>(Source: <a target="_blank" href="https://www.thecollector.com/renaissance-vs-baroque-what-are-the-differences/">The Collector</a>)</span>. The art also used new dynamics around light, depth,
                        and perspective, which allowed artists to create more realistic pieces that appeared to have more dimensions. Art from this period also focused more on reality, with many pieces being portraits
                        of relatable contexts, such as farming.
                    </p>
                    <br />
                    <p style={{fontSize: '1.15rem', textAlign: 'left', margin: '0', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', color: 'rgb(80, 30, 30)', fontWeight: 'bolder'}}>
                        * Disclaimer: For the best experience, please view this exhibition in full screen mode and begin by pressing the button below *
                    </p>
                    <br />
                    <Link to="/exhibit/late-medieval" type="button" className="circle next-previous" style={{fontSize: '1rem', width: '100px', alignSelf: 'center'}}>Begin Tour</Link>
                </div>
            }
        </>
    )
}

export default Artifact;