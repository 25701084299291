import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import Artifact from "../../../components/Artifact";
import { Link } from 'react-router-dom';

function EarlyRenaissance({ data }) {
    let params = useParams().artifact;
    let toDisplay;
    let artifacts = [];

    if (params === undefined) {
        toDisplay = <div className="period-intro" style={{position: 'relative', margin: '0', marginTop: '80px', marginBottom: '120px'}}>
            <h2 id="er-header" style={{width: 'fit-content', color: 'rgb(80, 30, 30)', borderTop: 'solid 3px rgb(80, 30, 30)', borderBottom: 'solid 3px rgb(80, 30, 30)', textAlign: 'center', alignSelf: 'center', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '20px', paddingRight: '20px'}}>Exhibit 2: Early Renaissance Art {'(c. 1400-1500)'}</h2>
            <p style={{fontSize: '1.15rem', textAlign: 'left', margin: '0', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', color: 'rgb(134, 56, 56)'}}>
                Early Renaissance art relied more on secular perspectives than the Late Medieval period, but continued to promote and enhance the humanism. This focus is displayed through
                art pieces that centered less on religious figures and more on an individual's perspective. There was still some religious influence,
                however, as demonstrated in "The Holy Trinity" by Masaccio.
            </p>
            <p style={{fontSize: '1.15rem', textAlign: 'left', margin: '0', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', color: 'rgb(134, 56, 56)'}}>
                This period also brought in novel art techniques, such as shadowing and sfumato, or color transitions. As a transition period in art, 
                this dynamic was likely onset by a newfound interest in science and reality. Likewise, this addition also made it possible for artists 
                to have more unique and individual styles, rather than having a uniform way of portraying art. Much of the art from this period
                was influenced by Greek and Roman culture.
                </p>
            <p style={{fontSize: '1.15rem', textAlign: 'left', margin: '0', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', color: 'rgb(134, 56, 56)'}}>
                The nature of the art in this period also became more scientific and centered around the human potential. Artists began to supplement their
                styles with alternative perspectives and realistic proportions, which opened the door to scientific foundations, such as da Vinvi's Vitruvian Man.
                This impact paved the way for a cultural shift, which gave society the genius of Pythagoras and others.&nbsp;
                <span style={{fontSize: '0.75rem'}}>(Sources: <a target="_blank" href="https://www.theartstory.org/movement/early-renaissance/">The Art Story</a>, <a target="_blank" href="https://www.nga.gov/features/slideshows/the-early-renaissance-in-florence.html">National Gallery of Art</a>)</span>
            </p>
            <p style={{fontSize: '1.15rem', textAlign: 'left', padding: '10px', color: 'rgb(134, 56, 56)'}}>
                <span style={{fontWeight: 'bold'}}>Featured Artists and Pieces:</span>
                <br />
                <Link to="/exhibit/early-renaissance/the-holy-trinity" className="exhibit-author">~ Masaccio - "The Holy Trinity"</Link>
                <br />
                <Link to="/exhibit/early-renaissance/self-portrait" className="exhibit-author">~ Leon Battista Alberti - "Self Portrait"</Link>
                
                <br />
                <Link to="/exhibit/early-renaissance/the-birth-of-venus" className="exhibit-author">~ Sandro Botticelli - "The Birth of Venus"</Link>
                <br />
                <Link to="/exhibit/early-renaissance/vitruvian-man" className="exhibit-author">~ Leonardo da Vinci - "Vitruvian Man"</Link>
            </p>
            <br />
            <Link to="/exhibit/early-renaissance/the-holy-trinity" type="button" className="circle next-previous" style={{fontSize: '1rem', width: '100px', alignSelf: 'center'}}>Enter Exhibit</Link>
            <Link to="/exhibit/late-medieval" type="button" style={{color: 'rgb(134, 56, 56)', textDecoration: 'none', fontSize: '0.75rem', width: '150px', alignSelf: 'center', paddingTop: '10px'}}>Return to Previous Period</Link>
        </div>
    } else {
        data.artifacts.map((artifact) => (artifacts.push(artifact)));

        artifacts.map((artifact) => {
            if (params === "the-holy-trinity") {
                {/*toDisplay = <Artifact artifact={artifact["the-holy-trinity"]} next={"/exhibit/early-renaissance/self-portrait"} previousPeriod={"/exhibit/late-medieval"} nextPeriod={"/exhibit/early-mid-renaissance"} />;*/}
                toDisplay = <Artifact artifact={artifact["the-holy-trinity"]} previous={"/exhibit/early-renaissance"} next={"/exhibit/early-renaissance/self-portrait"} />;
            } 
            else if (params === "self-portrait") {
                {/*toDisplay = <Artifact artifact={artifact["self-portrait"]} previous={"/exhibit/early-renaissance/the-holy-trinity"} previousPeriod={"/exhibit/late-medieval"} nextPeriod={"/exhibit/early-mid-renaissance"} />;*/}
                toDisplay = <Artifact artifact={artifact["self-portrait"]} previous={"/exhibit/early-renaissance/the-holy-trinity"} next={"/exhibit/early-renaissance/the-birth-of-venus"} />;
            } 
            else if (params === "the-birth-of-venus") {
                {/*toDisplay = <Artifact artifact={artifact["the-birth-of-venus"]} previousPeriod={"/exhibit/early-renaissance/"} nextPeriod={"/exhibit/mid-renaissance"} />;*/}
                toDisplay = <Artifact artifact={artifact["the-birth-of-venus"]} previous={"/exhibit/early-renaissance/self-portrait"} next={"/exhibit/early-renaissance/vitruvian-man"} />;
            }
            else {
                {/*toDisplay = <Artifact artifact={artifact["vitruvian-man"]} previousPeriod={"/exhibit/early-mid-renaissance"} next={"/exhibit/mid-renaissance/the-school-of-athens"} nextPeriod={"/exhibit/high-renaissance"} />;*/}
                toDisplay = <Artifact artifact={artifact["vitruvian-man"]} previous={"/exhibit/early-renaissance/the-birth-of-venus"} next={"/exhibit/high-renaissance"} />;
            }
        });
    }

    useEffect(() => {
      document.title = 'Early Renaissance';
    }, []);  

    return (
        <>
            <div>
                {toDisplay}
            </div>
        </>
    )
}

export default EarlyRenaissance;