import React, { useEffect } from 'react';
import Artifact from '../components/Artifact';

function Exhibit() {
    useEffect(() => {
      document.title = 'Exhibit';
    }, []);
    
    return (
        <div>
            <Artifact exhibitPage={true} />
        </div>
    )
}

export default Exhibit;