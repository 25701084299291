import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './layout/Layout';
import Home from './pages/Home';
import Exhibit from './pages/Exhibit';
import LateMedieval from './pages/exhibits/late_medieval/lm';
import EarlyRenaissance from './pages/exhibits/early_renaissance/er';
import EarlyMidRenaissance from './pages/exhibits/early_mid_renaissance/emr';
import MidRenaissance from './pages/exhibits/mid_renaissance/mr';
import HighRenaissance from './pages/exhibits/high_renaissance/hr';
import LateRenaissance from './pages/exhibits/late_renaissance/lr';
function getPeriodData(period) { 
  const data = {
    "period" : 
      {
          "late-medieval": [
              {
                  "name": "Late Medieval",
                  "start": "c. 1300",
                  "end": 1400,
                  "artifacts":  [
                      {
                          "the-last-judgement": 
                          {
                              "name": "The Last Judgement",
                              "artist":
                                {
                                    "name": "Giotto di Bondone",
                                    "dates": "c. 1267-1337",
                                    "period": "Late Medieval",
                                    "img": "https://artincontext.org/wp-content/uploads/2022/01/Painter-Giotto.jpg",
                                    "imgPage": "Art in Context",
                                    "info": "https://www.britannica.com/biography/Giotto-di-Bondone",
                                    "infoPage": "Britannica"
                                },
                              "artist-last": "di Bondone",
                              "year": "c. 1305",
                              "description": "\"The Last Judgement\" by Giotto di Bondone begins the journey of linear perspective because although it does not display all the spatial aspects of advanced linear perspective, it hints at the initial stages of its development. The figures in the painting are slightly more three-dimensional and naturalistic compared to earlier medieval works. Lines can be visualized down the center of the image and from different angles around the perimeter to represent the consideration the artist gave to realistic spacing in the art. You can also see an indication of this shift towards a linear perspective through the multiple rows of angels that are stacked and appear behind one another. Their proportions along with the more realistic portrayal of space were evidence of an approach that would eventually lead to the full development of linear perspective as time progressed to the early Renaissance.",
                              "img": "https://www.worldhistory.org/img/r/p/1500x1500/12678.jpg.webp?v=1699356363",
                              "imgPage": "World History Encyclopedia",
                              "info": "https://www.worldhistory.org/image/12678/the-last-judgement-by-giotto/",
                              "infoPage": "World History Encyclopedia",
                              "location": "Scrovegni Chapel, Padua, Italy",
                              "medium": "Fresco Painting"
                          }
                      }
                  ]
              }
          ],
          "early-renaissance": [
              {
                  "name": "Early Renaissance",
                  "start": "c. 1400",
                  "end": "1500",
                  "artifacts": [  
                      {
                          "the-holy-trinity": 
                          {
                              "name": "The Holy Trinity",
                              "artist": {
                                    "name": "Masaccio",
                                    "dates": "c. 1401-1428",
                                    "period": "Early Renaissance",
                                    "img": "https://italiantribune.com/wp-content/uploads/2021/08/080521-Red-as-Blood-Holy-Masaccio-Self-Portrait.jpg.webp",
                                    "imgPage": "Italian Tribune",
                                    "info": "https://www.britannica.com/biography/Masaccio",
                                    "infoPage": "Britannica"
                                },
                              "artist-last": "Masaccio",
                              "year": "c. 1427",
                              "description": "\"The Holy Trinity\" by Masaccio masterfully employs linear perspective to create a profound sense of depth and realism. This fresco uses vanishing points and converging lines to create an illusion of a three-dimensional space on a two-dimensional surface. The architectural elements of the depicted chapel and the strategic placement of the figures are aligned in such a way that they appear to recede realistically into the background. That linear detail together with the receding shading detail highlights the early Renaissance's innovative approach to spatial depiction and linear perspective in art. ",
                              "img": "https://cdn.britannica.com/93/248693-050-47CAFBCA/The-Trinity-fresco-by-Masaccio.jpg?w=300",
                              "imgPage": "Britannica",
                              "info": "https://smarthistory.org/masaccio-holy-trinity/",
                              "infoPage": "Smarthistory",
                              "location": "Santa Maria Novella, Florence, Italy",
                              "medium": "Fresco Painting"
                          },
                          "self-portrait": 
                          {
                              "name": "Self Portrait",
                              "artist": {
                                    "name": "Leon Battista Alberti",
                                    "dates": "1404-1472",
                                    "period": "Early Renaissance",
                                    "img": "https://www.theartstory.org/images20/new_design/bio/bio_alberti_leon_battista.jpg",
                                    "imgPage": "The Art Story",
                                    "info": "https://www.britannica.com/biography/Leon-Battista-Alberti",
                                    "infoPage": "Britannica"
                                },
                              "artist-last": "Alberti",
                              "year": "c. 1435",
                              "description": "\"Self-Portrait\" by Leon Battista Alberti is a significant piece because it reflects the artist’s renowned theoretical approach to art. This piece demonstrates his perspective leaning towards intellectual and humanist ideals rather than a direct application of linear perspective. The depiction of his side profile is heavily focused on self. It embraces self-awareness and individualism through the lack of detail outside himself.",
                              "img": "https://media.nga.gov/iiif/9fd0dc33-cca7-4821-8519-40c47ec08d19/full/full/0/default.jpg?attachment_filename=self-portrait_1957.14.125.jpg",
                              "imgPage": "National Gallery of Art",
                              "info": "https://www.nga.gov/collection/art-object-page.43845.html",
                              "infoPage": "National Gallery of Art",
                              "location": "National Gallery of Art, Washington, D.C, USA",
                              "medium": "Bronze Sculpture"
                          },
                          "the-birth-of-venus": 
                          {
                              "name": "The Birth of Venus",
                              "artist": {
                                "name": "Sandro Botticelli",
                                "dates": "c. 1445-1510",
                                "period": "Early Renaissance",
                                "img": "https://www.nationalgallery.org.uk/media/30073/botticelli-sandro-c-face-half.jpg?rxy=0.52960526315789469,0.28358208955223879&width=430&bgcolor=fff&rnd=132138115432970000",
                                "imgPage": "The National Gallery",
                                "info": "https://www.britannica.com/biography/Sandro-Botticelli",
                                "infoPage": "Britannica"
                              },
                              "artist-last": "Botticelli",
                              "year": "c. 1486",
                              "description": "\"The Birth of Venus\" by Sandro Botticelli forfeits the principle of linear perspective and is another example of humanism and mythological ideals. The realistic perspective is a backhand priority as art heavily focuses on embracing the mythological birth of the goddess, Venus. It reflects humanist ideals primarily by celebrating the human body and its praising beauty. This piece is a fitting example of the beginning shift towards humanist ideals over linear perspective in the chronological progression from late medieval times to the Renaissance. ",
                              "img": "https://www.datocms-assets.com/103094/1688659635-1503909239806647-605174-2.jpg?auto=format&max-w=800",
                              "imgPage": "Uffizi Gallery Museum",
                              "info": "https://www.uffizi.it/en/artworks/birth-of-venus",
                              "infoPage": "Uffizi Gallery Museum",
                              "location": "Uffizi Gallery, Florence, Italy",
                              "medium": "Canvas Painting"
                          },
                          "vitruvian-man": 
                          {
                              "name": "Vitruvian Man",
                              "artist": {
                                "name": "Leonardo da Vinci",
                                "dates": "c. 1452-1519",
                                "period": ["Early Renaissance", "High Renaissance"],
                                "img": "https://cdn.britannica.com/57/194757-050-FD0A1CAA/Portrait-Leonardo-da-Vinci.jpg",
                                "imgPage": "Britannica",
                                "info": "https://www.britannica.com/biography/Leonardo-da-Vinci",
                                "infoPage": "Britannica"
                              },
                              "artist-last": "da Vinci",
                              "year": "c. 1490",
                              "description": "\"Vitruvian Man\" by Leonardo da Vinci is a perfect placement chronologically of the balance between humanism and linear perspective in the given timeline. It embraces linear perspective through the placement and linear balance between limbs and body. This piece is mathematical in its precision. Yet, humanism is demonstrated through the embracement of the human body. It celebrates the science of the human body through its calculated perfection and symmetry. The balance between the two principles falls beautifully into place as it lies perfectly between the two time periods and the shift between the two principles.",
                              "img": "https://media-cldnry.s-nbcnews.com/image/upload/t_fit-760w,f_auto,q_auto:best/MSNBC/Components/Photo/_new/120131-leonardo-da-vinci-vitruvian-man-8a.jpg",
                              "imgPage": "NBC News",
                              "info": "https://www.britannica.com/topic/Vitruvian-man",
                              "infoPage": "Britannica",
                              "location": "Gallerie dell'Accademia, Venice, Italy",
                              "medium": "Pen and Watercolor Drawing Over Metalpoint on Paper"
                          }
                      }
                  ]
              }
          ],
          /*"early-mid-renaissance": [
              {
                  "name": "Early-Mid Renaissance",
                  "start": "c. 1450",
                  "end": 1500,
                  "artifacts": [
                      {
                          "the-birth-of-venus": 
                          {
                              "name": "The Birth of Venus",
                              "artist": {
                                "name": "Sandro Botticelli",
                                "dates": "c. 1445-1510",
                                "period": "Early-Mid Renaissance",
                                "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/Sandro_Botticelli_083.jpg/800px-Sandro_Botticelli_083.jpg"
                              },
                              "artist-last": "Botticelli",
                              "year": "c. 1486",
                              "description": "The Birth of Venus is a painting by the Italian artist Sandro Botticelli probably made in the mid 1480s. It depicts the goddess Venus arriving at the shore after her birth, when she had emerged from the sea fully-grown (called Venus Anadyomene and often depicted in art). The painting is in the Uffizi Gallery in Florence, Italy.",
                              "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Sandro_Botticelli_-_La_nascita_di_Venere_-_Google_Art_Project_-_edited.jpg/1920px-Sandro_Botticelli_-_La_nascita_di_Venere_-_Google_Art_Project_-_edited.jpg",
                              "location": "",
                              "medium": "painting"
                          }
                      }
                  ]
              }
          ],
          "mid-renaissance": [ 
              { 
                  "name": "Mid Renaissance",
                  "start": "c. 1500",
                  "end": 1550,
                  "artifacts": [
                      {
                          "vitruvian-man": 
                          {
                              "name": "Vitruvian Man",
                              "artist": {
                                "name": "Leonardo da Vinci",
                                "dates": "c. 1452-1519",
                                "period": ["Early Renaissance", "High Renaissance"],
                                "img": "https://cdn.britannica.com/57/194757-050-FD0A1CAA/Portrait-Leonardo-da-Vinci.jpg"
                              },
                              "artist-last": "da Vinci",
                              "year": "c. 1490",
                              "description": "",
                              "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/22/Da_Vinci_Vitruve_Luc_Viatour.jpg/800px-Da_Vinci_Vitruve_Luc_Viatour.jpg",
                              "location": "",
                              "medium": "painting"
                          },
                          "the-school-of-athens": 
                          {
                              "name": "The School of Athens",
                              "artist": {
                                "name": "Raphael",
                                "dates": "c. 1483-1520",
                                "period": "Mid Renaissance",
                                "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Raffaello_Sanzio.jpg/800px-Raffaello_Sanzio.jpg"
                              },
                              "artist-last": "Raphael",
                              "year": "c. 1511",
                              "description": "The School of Athens (Italian:  Scuola di Atene) is a fresco by the Italian Renaissance artist Raphael. It was painted between 1509 and 1511 as a part of Raphael's commission to decorate the rooms now known as the Stanze di Raffaello, in the Apostolic Palace in the Vatican. The Stanza della Segnatura was the first of the rooms to be decorated, and The School of Athens, representing Philosophy, was probably the second painting to be finished there, after La Disputa (Theology) on the opposite wall, and the Parnassus (Literature).",
                              "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/%22The_School_of_Athens%22_by_Raffaello_Sanzio_da_Urbino.jpg/1280px-%22The_School_of_Athens%22_by_Raffaello_Sanzio_da_Urbino.jpg",
                              "location": "",
                              "medium": "painting"
                          }
                      }
                  ]
              }
          ],*/
          "high-renaissance": [
              {
                  "name": "High Renaissance",
                  "start": "c. 1490",
                  "end": 1530,
                  "artifacts": [
                      {
                        "mona-lisa":
                        {
                            "name": "Mona Lisa",
                            "artist": {
                                "name": "Leonardo da Vinci",
                                "dates": "c. 1452-1519",
                                "period": ["Early Renaissance", "High Renaissance"],
                                "img": "https://cdn.britannica.com/57/194757-050-FD0A1CAA/Portrait-Leonardo-da-Vinci.jpg",
                                "imgPage": "Britannica",
                                "info": "https://www.britannica.com/biography/Leonardo-da-Vinci",
                                "infoPage": "Britannica"
                            },
                            "artist-last": "da Vinci",
                            "year": "c. 1503",
                            "description": "\"Mona Lisa\" by Leonardo da Vinci reflects humanism in its centrality to the individual. It skillfully gives attention to subtle expressions and naturality that rest hidden in her face. The work of art reflects the complexity of the individual. The piece is ridden with personality and emotion, yet it is all composed with a single smirk. Proceeding into the high and late Renaissance, this art is a notable example of the shift of leaving behind linear perspective and focusing entirely on humanism.",
                            "img": "https://smarthistory.org/wp-content/uploads/2022/07/Mona_Lisa_by_Leonardo_da_Vinci_from_C2RMF_retouched-870x1297.jpg",
                            "imgPage": "Smarthistory",
                            "info": "https://www.britannica.com/topic/Mona-Lisa-painting",
                            "infoPage": "Britannica",
                            "location": "Louvre, Paris, France",
                            "medium": "Oil Painting on Poplar Panel"
                        },
                        "the-school-of-athens": 
                        {
                            "name": "The School of Athens",
                            "artist": {
                                "name": "Raphael",
                                "dates": "c. 1483-1520",
                                "period": "High Renaissance",
                                "img": "https://www.nationalgallery.org.uk/media/30159/raphael-c-face-half.jpg?rxy=0.59539473684210531,0.40671641791044777&width=430&bgcolor=fff&rnd=132138121185570000",
                                "imgPage": "The National Gallery",
                                "info": "https://www.britannica.com/biography/Raphael-Italian-painter-and-architect",
                                "infoPage": "Britannica"
                            },
                            "artist-last": "Raphael",
                            "year": "c. 1511",
                            "description": "\"The School of Athens\" by Raphael is another example of this balance between humanism and linear perspective. Linear perspective is evident in the work through the depth of the image. Although the canvas is a 2-dimensional object, it accurately feels 3-dimensional through the shading and proportion of the receding arches. Even humans gradually seem to shrink farther off into the distance they get. The art also demonstrates humanism through the different statues viewed at the bottom. They almost feel mythological yet prove to celebrate the essence of the human body. Humanism is also in the statues at the top corners of the great hall. The image depicts a culture of self-celebration and body glorification. Humanism is also evident through the different human personality types and characteristics seen in everyone.",
                            "img": "https://www.raphaelpaintings.org/images/paintings/the-school-of-athens.jpg",
                            "imgPage": "Raphael Paintings",
                            "info": "https://www.museivaticani.va/content/museivaticani/en/collezioni/musei/stanze-di-raffaello/stanza-della-segnatura/scuola-di-atene.html",
                            "infoPage": "Vatican Museums",
                            "location": "Musei Vaticani, Vatican City",
                            "medium": "Fresco Painting"
                        },
                          "assumption-of-the-virgin": 
                          {
                              "name": "Assumption of the Virgin",
                              "artist": {
                                "name": "Titian",
                                "dates": "c. 1488-1576",
                                "period": ["High Renaissance", "Late Renaissance"],
                                "img": "https://www.nationalgallery.org.uk/media/30183/titian-c-face-half.jpg?width=430&bgcolor=fff&rnd=132067240417130000",
                                "imgPage": "The National Gallery",
                                "info": "https://www.britannica.com/biography/Titian",
                                "infoPage": "Britannica"
                              },
                              "artist-last": "Titian",
                              "year": "c. 1516",
                              "description": "\"Assumption of the Virgin\" by Titian only subtly uses linear perspective. It is seen throughout the perimeter of the piece. The painting portrays the Virgin Mary's ascent to heaven, but rather than focusing on spatial realism through linear perspective, Titian emphasizes the emotional and spiritual aspects of the scene. The work is more concerned with capturing the divine and human aspects of Mary, aligning with the humanist focus on individual expression and emotion, a characteristic of the High Renaissance. The cultural significance of the mother, Mary, was evident in the Catholic dominant culture which influenced and encouraged her focus on the art.",
                              "img": "https://www.titian.org/assets/img/paintings/the-assumption-of-the-virgin.jpg",
                              "imgPage": "Titian.org",
                              "info": "https://www.britannica.com/topic/Assumption-of-the-Virgin-by-Titian",
                              "infoPage": "Britannica",
                              "location": "Basilica di Santa Maria Gloriosa dei Frari, Venice, Italy",
                              "medium": "Oil Painting on Panel"
                          }
                      }
                  ]
              }
          ],
          "late-renaissance": [
              {
                  "name": "Late Renaissance",
                  "start": "c. 1530",
                  "end": 1600,
                  "artifacts": [
                      {
                          "bacchus-and-ariadne":
                          {
                              "name": "Bacchus and Ariadne",
                              "artist": {
                                "name": "Titian",
                                "dates": "c. 1488-1576",
                                "period": ["High Renaissance", "Late Renaissance"],
                                "img": "https://www.nationalgallery.org.uk/media/30183/titian-c-face-half.jpg?width=430&bgcolor=fff&rnd=132067240417130000",
                                "imgPage": "The National Gallery",
                                "info": "https://www.britannica.com/biography/Titian",
                                "infoPage": "Britannica"
                              },
                              "artist-last": "Titian",
                              "year": "c. 1523",
                              "description": "\"Bacchus and Ariadne\" by Titian emphasizes humanism rather than a linear perspective. The painting vividly portrays mythological figures, the god of wine and the daughter of King Minos of Crete, with dynamic emotions and movements, capturing the humanistic interest in classical mythology and the exploration of human experiences and passions. While there is a sense of space and depth, the focus on linear perspective is so inevitable that it should hardly even be considered. Instead, Titian's use of color, composition, and expression highlights the humanistic elements of the painting, bringing mythological stories to life with a sense of drama and vitality. It also vividly illustrates humanistic passion and desire as it depicts the story of Bacchus leaping from his chariot to pursue Ariadne.",
                              "img": "https://d3d00swyhr67nd.cloudfront.net/w800h800/collection/NG/NG/NG_NG_NG35-001.jpg",
                              "imgPage": "Art UK",
                              "info": "https://www.nationalgallery.org.uk/paintings/titian-bacchus-and-ariadne",
                              "infoPage": "The National Gallery",
                              "location": "National Gallery, London, England",
                              "medium": "Oil on Canvas"
                          },
                          "the-harvesters":
                          {
                              "name": "The Harvesters",
                              "artist": {
                                "name": "Pieter Bruegel, the Elder",
                                "dates": "c. 1525-1569",
                                "period": "Late Renaissance",
                                "img": "https://www.theartstory.org/images20/new_design/bio/bio_bruegel_the_elder_pieter.jpg",
                                "imgPage": "The Art Story",
                                "info": "https://www.britannica.com/biography/Pieter-Bruegel-the-Elder",
                                "infoPage": "Britannica"
                              },
                              "artist-last": "Bruegel the Elder",
                              "year": "c. 1565",
                              "description": "\"The Harvesters\" by Pieter Bruegel the Elder is not as ignorant of linear perspective as the previous piece. It has a beautiful 3-dimensional depth over the landscape. However, the focus is deeply biased towards humanism. The painting depicts peasant life, highlighting everyday activities and the natural world. Bruegel pays close attention to the details of rural life, the cycles of nature, and the dignity of peasant labor that reflect the humanist interest in the real, everyday human experience, such as harvesting wheat. He thus moves away from the religious and mythological themes prevalent in earlier art. While the painting does exhibit spatial depth, the humanist elements depicting ordinary life and nature are more pronounced.",
                              "img": "https://collectionapi.metmuseum.org/api/collection/v1/iiif/435809/794356/main-image",
                              "imgPage": "Metropolitan Museum of Art",
                              "info": "https://www.metmuseum.org/art/collection/search/435809",
                              "infoPage": "Metropolitan Museum of Art",
                              "location": "Metropolitan Museum of Art, New York City, New York, USA",
                              "medium": "Oil on Wood Painting"
                          },
                          "the-calling-of-saint-matthew":
                          {
                              "name": "The Calling of Saint Matthew",
                              "artist": {
                                "name": "Caravaggio",
                                "dates": "c. 1571-1610",
                                "period": "Late Renaissance",
                                "img": "https://www.nationalgallery.org.uk/media/30078/caravaggio-michelangelo-merisia-da-c-face-half.jpg?rxy=0.48026315789473684,0.53731343283582089&width=430&bgcolor=fff&rnd=132821619053830000",
                                "imgPage": "The National Gallery",
                                "info": "https://www.britannica.com/biography/Caravaggio",
                                "infoPage": "Britannica"
                              },
                              "artist-last": "Caravaggio",
                              "year": "c. 1599",
                              "description": "\"The Calling of Saint Matthew\" by Caravaggio completely ignores any significant linear perspective as it has no depth nor need for spatial awareness. It reflects the humanist aspect in the realistic portrayal of Jesus Christ, Matthew, and others, capturing the emotional depth of the scene. Jesus enters the room and points at Matthew, who is counting money at a table with other men. This scene represents the moment Jesus calls Matthew to follow him, a pivotal event marking Matthew's spiritual transformation. Caravaggio's use of dramatic lighting and intense realism emphasizes human experience and emotions, aligning with humanist ideals. The focus is more on capturing the moment of Matthew's spiritual awakening and the human interactions, rather than on the technical aspects of linear perspective.",
                              "img": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/The_Calling_of_Saint_Matthew-Caravaggo_%281599-1600%29.jpg/1024px-The_Calling_of_Saint_Matthew-Caravaggo_%281599-1600%29.jpg",
                              "imgPage": "Wikipedia",
                              "info": "https://smarthistory.org/caravaggio-calling-of-st-matthew/",
                              "infoPage": "Smarthistory",
                              "location": "San Luigi dei Francesi, Rome, Italy",
                              "medium": "Oil on Canvas Painting"
                          }
                      }
                  ]
              }
          ]
      }
  }

  if (period !== "all") {
    return data.period[period][0];
  }
  else {
    //console.log(data.period["late-medieval"][0].artifacts[0]["the-last-judgement"].name);
    return data.period;
  }
}

function App() {
  return (
    <div className="App">
        <Layout myjson={getPeriodData('all')}>
            <Routes>
                <Route path="/" element={<Navigate to="/exhibit" replace />} />
                <Route path="/exhibit" element={<Exhibit />} />
                <Route path="/exhibit/late-medieval" element={<LateMedieval data={getPeriodData('late-medieval')} />} />
                <Route path="/exhibit/late-medieval/:artifact" element={<LateMedieval data={getPeriodData('late-medieval')} />} />
                <Route path="/exhibit/early-renaissance" element={<EarlyRenaissance data={getPeriodData('early-renaissance')} />} />
                <Route path="/exhibit/early-renaissance/:artifact" element={<EarlyRenaissance data={getPeriodData('early-renaissance')} />} />
                {/*<Route path="/exhibit/early-mid-renaissance" element={<EarlyMidRenaissance data={getPeriodData('early-mid-renaissance')} />} />
                <Route path="/exhibit/early-mid-renaissance/:artifact" element={<EarlyMidRenaissance data={getPeriodData('early-mid-renaissance')} />} />
                <Route path="/exhibit/mid-renaissance" element={<MidRenaissance data={getPeriodData('mid-renaissance')} />} />
                <Route path="/exhibit/mid-renaissance/:artifact" element={<MidRenaissance data={getPeriodData('mid-renaissance')} />} />*/}
                <Route path="/exhibit/high-renaissance" element={<HighRenaissance data={getPeriodData('high-renaissance')} />} />
                <Route path="/exhibit/high-renaissance/:artifact" element={<HighRenaissance data={getPeriodData('high-renaissance')} />} />
                <Route path="/exhibit/late-renaissance" element={<LateRenaissance data={getPeriodData('late-renaissance')} />} />
                <Route path="/exhibit/late-renaissance/:artifact" element={<LateRenaissance data={getPeriodData('late-renaissance')} />} />
                <Route path="*" element={<Navigate to="/exhibit" replace />} />
            </Routes>
        </Layout>
    </div>
  );
}

export default App;
