import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import Artifact from "../../../components/Artifact";
import { Link } from 'react-router-dom';

function LateMedieval({ data }) {
    let params = useParams().artifact;
    let toDisplay;
    let artifacts = [];

    if (params === undefined) {
        toDisplay = <div className="period-intro" style={{position: 'relative', margin: '0', marginTop: '80px', marginBottom: '120px'}}>
            <h2 id="lm-header" style={{width: 'fit-content', color: 'rgb(80, 30, 30)', borderTop: 'solid 3px rgb(80, 30, 30)', borderBottom: 'solid 3px rgb(80, 30, 30)', textAlign: 'center', alignSelf: 'center', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '20px', paddingRight: '20px'}}>Exhibit 1: Late Medieval Art {'(c. 1300-1400)'}</h2>
            <p style={{fontSize: '1.15rem', textAlign: 'left', margin: '0', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', color: 'rgb(134, 56, 56)'}}>
                Late Medieval art emphasized religious, primarily Catholic, perspectives and biblical themes.
                Much like the art of other periods, many pieces of art were intended for use in architecture,
                including chapels and places of worship. There is also an emphasis on vibrant colors and high
                contrast, which helps allow the viewer of the piece to grasp the fullness of the art. Light was also 
                used as an accent to provide symbolism, particularly towards divinity.
            </p>
            <p style={{fontSize: '1.15rem', textAlign: 'left', margin: '0', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', color: 'rgb(134, 56, 56)'}}>
                The Late Medieval period is also known for its narration and fresco technique. This technique was
                special because it allowed artists to paint over wet plaster, allowing the image to be maintained
                for longer periods of historical time. The art was also specialized to local tendencies, which
                allowed local artists to render their images based after their local ideals.
            </p>
            <p style={{fontSize: '1.15rem', textAlign: 'left', margin: '0', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', color: 'rgb(134, 56, 56)'}}>
                Dynamics of art also began to change during this period as artists began to focus more on their individual
                styles and techniques. This was a shift from the previous period, where art was more uniform and less unique
                from one piece to another. This shift was likely due to the rise of humanism, which emphasized the importance
                of creating images around a circumstance, rather than a general theme.&nbsp;
                <span style={{fontSize: '0.75rem'}}>(Sources: <a target="_blank" href="https://www.britannica.com/art/Gothic-art/High-Gothic">Britannica</a>, <a target="_blank" href="https://www.metmuseum.org/toah/hd/iptg/hd_iptg.htm">Metropolitan Museum</a>)</span>
            </p>
            <p style={{fontSize: '1.15rem', textAlign: 'left', padding: '10px', color: 'rgb(134, 56, 56)'}}>
                <span style={{fontWeight: 'bold'}}>Featured Artists and Pieces:</span>
                <br />
                <Link  style={{color: 'rgb(134, 56, 56)'}} to="/exhibit/late-medieval/the-last-judgement" className="exhibit-author">~ Giotto di Bondone - "The Last Judgement"</Link>
            </p>
            
            <Link to="/exhibit/late-medieval/the-last-judgement" type="button" className="circle next-previous" style={{fontSize: '1rem', width: '100px', alignSelf: 'center'}}>Enter Exhibit</Link>
            <Link to="/exhibit" type="button" style={{color: 'rgb(80, 30, 30)', textDecoration: 'none', fontSize: '0.75rem', width: '150px', alignSelf: 'center', paddingTop: '10px'}}>Return to Introduction</Link>
        </div>
    } else {
        data.artifacts.map((artifact) => (artifacts.push(artifact)));

        artifacts.map((artifact) => {
            if (params === "the-last-judgement") {
                {/*toDisplay = <Artifact artifact={artifact["the-last-judgement"]} previousPeriod={"/exhibit/late-renaissance"} nextPeriod={"/exhibit/early-renaissance"} />;*/}
                toDisplay = <Artifact artifact={artifact["the-last-judgement"]} previous={"/exhibit/late-medieval"} next={"/exhibit/early-renaissance"} />;
            }
        });
    }

    useEffect(() => {
        document.title = 'Late Medieval';
      }, []);
      
    return (
        <>
            <div>
                {toDisplay}
            </div>
        </>
    )
}

export default LateMedieval;