import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import Artifact from "../../../components/Artifact";
import { Link } from 'react-router-dom';

function HighRenaissance({ data }) {
    let params = useParams().artifact;
    let toDisplay;

    if (params === undefined) {
        toDisplay = <div className="period-intro" style={{position: 'relative', margin: '0', marginTop: '80px', marginBottom: '120px'}}>
            <h2 id="hr-header" style={{width: 'fit-content', color: 'rgb(80, 30, 30)', borderTop: 'solid 3px rgb(80, 30, 30)', borderBottom: 'solid 3px rgb(80, 30, 30)', textAlign: 'center', alignSelf: 'center', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '20px', paddingRight: '20px'}}>Exhibit 3: High Renaissance Art {'(c. 1500-1520)'}</h2>
            <p style={{fontSize: '1.15rem', textAlign: 'left', margin: '0', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', color: 'rgb(134, 56, 56)'}}>
                The High Renaissance Period continued looking towards the classical roots of Italian art. In terms of popular pieces, this is likely 
                the most well-known period of art because it included artists, such as da Vinci, Michelangelo, and Raphael. During this period, artists 
                continued to explore the human potential, emphasizing the importance of the human body and a devotion to reality and religion.
            </p>
            <p style={{fontSize: '1.15rem', textAlign: 'left', margin: '0', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', color: 'rgb(134, 56, 56)'}}>
                This period also further emphasized science and mathematics, which were popular topics among scholars. There was also a transition to refined art, 
                which gave a medium for artists to express depth and purpose in their art, beyond the color scheme. Light continued to be a tool for artists, 
                but it was used to emphasize the importance of the subject, rather than to provide a sense of divinity.
            </p>
            <p style={{fontSize: '1.15rem', textAlign: 'left', margin: '0', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', color: 'rgb(134, 56, 56)'}}>
                During this period, artists also began to make art less for the purpose of creating beauty, but rather for the fame and fortune associated with the title of "Artist".
                This dynamic likely played a role in furthering humanism and uniqueness in the art. This period is also unique from the others because there is a generally accepted
                end point (the Death of Raphael), which the other periods do not have.&nbsp;
                <span style={{fontSize: '0.75rem'}}>(Source: <a target="_blank" href="https://www.theartstory.org/movement/high-renaissance/">The Art Story</a>)</span>
            </p>
            <p style={{fontSize: '1.15rem', textAlign: 'left', padding: '10px', color: 'rgb(134, 56, 56)'}}>
                <span style={{fontWeight: 'bold'}}>Featured Artists and Pieces:</span>
                <br />
                <Link style={{color: 'rgb(134, 56, 56)'}} to="/exhibit/high-renaissance/mona-lisa" className="exhibit-author">~ Leonardo da Vinci - "Mona Lisa"</Link>
                <br />
                <Link style={{color: 'rgb(134, 56, 56)'}} to="/exhibit/high-renaissance/the-school-of-athens" className="exhibit-author">~ Raphael - "The School of Athens"</Link>
                <br />
                <Link style={{color: 'rgb(134, 56, 56)'}} to="/exhibit/high-renaissance/assumption-of-the-virgin" className="exhibit-author">~ Titian - "Assumption of the Virgin"</Link>
            </p>
            <br />
            <Link to="/exhibit/high-renaissance/mona-lisa" type="button" className="circle next-previous" style={{fontSize: '1rem', width: '100px', alignSelf: 'center'}}>Enter Exhibit</Link>
            <Link to="/exhibit/early-renaissance" type="button" style={{color: 'rgb(80, 30, 30)', textDecoration: 'none', fontSize: '0.75rem', width: '150px', alignSelf: 'center', paddingTop: '10px'}}>Return to Previous Period</Link>
        </div>
    } else {
        let artifacts = [];
        data.artifacts.map((artifact) => (artifacts.push(artifact)));

        artifacts.map((artifact) => {
            if (params === "assumption-of-the-virgin") {
                {/*toDisplay = <Artifact artifact={artifact["assumption-of-the-virgin"]} previousPeriod={"/exhibit/mid-renaissance"} next={"/exhibit/high-renaissance/mona-lisa"} nextPeriod={"/exhibit/late-renaissance"} />;*/}
                toDisplay = <Artifact artifact={artifact["assumption-of-the-virgin"]} previous={"/exhibit/high-renaissance/the-school-of-athens"} next={"/exhibit/late-renaissance"} />;

            } 
            else if (params === "the-school-of-athens") {
                {/*toDisplay = <Artifact artifact={artifact["the-school-of-athens"]} previousPeriod={"/exhibit/early-mid-renaissance"} previous={"/exhibit/mid-renaissance/vitruvian-man"} nextPeriod={"/exhibit/high-renaissance"} />;*/}
                toDisplay = <Artifact artifact={artifact["the-school-of-athens"]} previous={"/exhibit/high-renaissance/mona-lisa"} next={"/exhibit/high-renaissance/assumption-of-the-virgin"} />;
            } 
            else {
                {/*toDisplay = <Artifact artifact={artifact["mona-lisa"]} previousPeriod={"/exhibit/mid-renaissance"} previous={"/exhibit/high-renaissance/assumption-of-the-virgin"} nextPeriod={"/exhibit/late-renaissance"} />;*/}
                toDisplay = <Artifact artifact={artifact["mona-lisa"]} previous={"/exhibit/high-renaissance"} next={"/exhibit/high-renaissance/the-school-of-athens"} />;
            }
        });
    }

    useEffect(() => {
        document.title = 'High Renaissance';
      }, []);
      
    return (
        <div>
            {toDisplay}
        </div>
    )
}

export default HighRenaissance;