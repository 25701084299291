import React from "react";
import { Link } from 'react-router-dom';

function Previous({ url=null, marginRight=false }){
    return (
        (marginRight) ? 
            <Link to={url} className="circle next-previous" style={{fontSize: '1rem', marginRight: '15px'}}>Previous</Link>
            :
            <Link to={url} className="circle next-previous" style={{fontSize: '1rem'}}>Previous</Link>
    );
}

export default Previous;