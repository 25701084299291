import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import Artifact from "../../../components/Artifact";
import { Link } from 'react-router-dom';

function LateRenaissance({ data }) {
    let params = useParams().artifact;
    let toDisplay;

    if (params === undefined) {
        toDisplay = <div className="period-intro" style={{position: 'relative', margin: '0', marginTop: '80px', marginBottom: '120px'}}>
            <h2 id="lr-header" style={{width: 'fit-content', color: 'rgb(80, 30, 30)', borderTop: 'solid 3px rgb(80, 30, 30)', borderBottom: 'solid 3px rgb(80, 30, 30)', textAlign: 'center', alignSelf: 'center', paddingTop: '5px', paddingBottom: '5px', paddingLeft: '20px', paddingRight: '20px'}}>Exhibit 4: Late Renaissance Art {'(c. 1520-1600)'}</h2>
            <p style={{fontSize: '1.15rem', textAlign: 'left', margin: '0', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', color: 'rgb(134, 56, 56)'}}>
                The start of the Late Renaissance period is often correlated with the death of High Renaissance artist, Raphael. Late Renaissance art was emphasized by mannerism {'distortion'}, and complex composition. Through this method of art, the artists were able to
                express their art in new ways that had not been done before. These elements allowed the artists to present their pieces with depth and personality
                that highlighted the importance of the subject in the foreground, and accenting features in the back.
            </p>
            <p style={{fontSize: '1.15rem', textAlign: 'left', margin: '0', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', color: 'rgb(134, 56, 56)'}}>
                This period also emphasized the importance of the artist, rather than the subject, which was different from the High Renaissance period. 
                This was a time that allowed artists to explore perception and express themselves with more than just color and shadows. The addition of
                these features also provide a medium for presenting emotion and experimentation in a way that could be appreciated by viewers.
            </p>
            <p style={{fontSize: '1.15rem', textAlign: 'left', margin: '0', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', color: 'rgb(134, 56, 56)'}}>
                Space was utilized in this period to enhance the perspective of the painting. This helped the artist depict realistic activities, such as that found in
                "The Harvesters", and gave the artist the space interact with the world around the subject. Likewise, by using the space in this way, the artist could include
                shadows to portray sentiment, unlike what had been accomplished in previous periods.&nbsp;
                <span style={{fontSize: '0.75rem'}}>(Sources: <a target="_blank" href="https://useum.org/Baroque/History-of-Baroque">UMuseum</a>, <a target="_blank" href="https://www.thecollector.com/renaissance-vs-baroque-what-are-the-differences/">The Collector</a>)</span>
            </p>
            <p style={{fontSize: '1.15rem', textAlign: 'left', padding: '10px', color: 'rgb(134, 56, 56)'}}>
                <span style={{fontWeight: 'bold'}}>Featured Artists and Pieces:</span>
                <br />
                <Link style={{color: 'rgb(134, 56, 56)'}} to="/exhibit/late-renaissance/bacchus-and-ariadne" className="exhibit-author">~ Titian - "Bacchus and Ariadne"</Link>
                <br />
                <Link style={{color: 'rgb(134, 56, 56)'}} to="/exhibit/late-renaissance/the-harvesters" className="exhibit-author">~ Pieter Bruegel the Elder - "The Harvesters"</Link>
                <br />
                <Link style={{color: 'rgb(134, 56, 56)'}} to="/exhibit/late-renaissance/the-calling-of-saint-matthew" className="exhibit-author">~ Caravaggio - "The Calling of Saint Matthew"</Link>
            </p>
            <br />
            <Link to="/exhibit/late-renaissance/bacchus-and-ariadne" type="button" className="circle next-previous" style={{fontSize: '1rem', width: '100px', alignSelf: 'center'}}>Enter Exhibit</Link>
            <Link to="/exhibit/high-renaissance" type="button" style={{color: 'rgb(80, 30, 30)', textDecoration: 'none', fontSize: '0.75rem', width: '150px', alignSelf: 'center', paddingTop: '10px'}}>Return to Previous Period</Link>
        </div>
    } else {
        let artifacts = [];
        data.artifacts.map((artifact) => (artifacts.push(artifact)));

        artifacts.map((artifact) => {
            if (params === "bacchus-and-ariadne") {
                {/*toDisplay = <Artifact artifact={artifact["bacchus-and-ariadne"]} previousPeriod={"/exhibit/high-renaissance"} next={"/exhibit/late-renaissance/the-harvesters"} nextPeriod={"/exhibit/late-medieval"} />;*/}
                toDisplay = <Artifact artifact={artifact["bacchus-and-ariadne"]} previous={"/exhibit/late-renaissance"} next={"/exhibit/late-renaissance/the-harvesters"} />;
            } else if (params === "the-harvesters") {
                {/*toDisplay = <Artifact artifact={artifact["the-harvesters"]} previousPeriod={"/exhibit/high-renaissance"} previous={"/exhibit/late-renaissance/bacchus-and-ariadne"} next={"/exhibit/late-renaissance/the-calling-of-saint-matthew"} nextPeriod={"/exhibit/late-medieval"} />;*/}
                toDisplay = <Artifact artifact={artifact["the-harvesters"]} previous={"/exhibit/late-renaissance/bacchus-and-ariadne"} next={"/exhibit/late-renaissance/the-calling-of-saint-matthew"} />;
            } else {
                {/*toDisplay = <Artifact artifact={artifact["the-calling-of-saint-matthew"]} previousPeriod={"/exhibit/high-renaissance"} previous={"/exhibit/late-renaissance/the-harvesters"} nextPeriod={"/exhibit/late-medieval"} />;*/}
                toDisplay = <Artifact artifact={artifact["the-calling-of-saint-matthew"]} previous={"/exhibit/late-renaissance/the-harvesters"} next={"/exhibit"} />;
            }
        });
    }

    useEffect(() => {
        document.title = 'Late Renaissance';
      }, []);
      
    return (
        <div>
            {toDisplay}
        </div>
    )
}

export default LateRenaissance;